import "@iproov/web-sdk"
import { fetchToken, TokenPayload } from "../../services/tokenProvider"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Loader } from "../Loader"
import { SDKOptions, TransactionConfiguration } from "./TransactionConfig"
import { MaybeUndefined } from "../../global"

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["iproov-me"]: IProovElementProps
    }
  }
}

export type IProovElementProps = React.HTMLAttributes<HTMLElement> &
  SDKOptions & {
    ref: any
    token: string
    base_url: string
    custom_title: string
  }

export type IProovProps = TransactionConfiguration & {
  onDone: () => void
}

type SDKEvents =
  | "passed"
  | "canceled"
  | "error"
  | "failed"
  | "permission_denied"
  | "permission"
  | "unsupported"
  | "progress"
  | "ready"
type EventCallback = (() => void) | ((event: SDKEventData) => void)
type EventListeners = { [event in SDKEvents]: EventCallback }

type SDKEventData = {
  detail: {
    progress: number
    message: string
  }
  srcElement: HTMLElement
}

function useHookWithRefCallback(events: EventListeners) {
  const ref = useRef<HTMLElement>()
  const setRef = useCallback(
    (node: HTMLElement) => {
      // Make sure to cleanup any events/references added to the last instance
      const keys: SDKEvents[] = Object.keys(events) as SDKEvents[]
      keys.forEach((event) => {
        if (ref && ref.current) {
          // @ts-ignore
          ref.current.removeEventListener(event, events[event])
        }

        if (node) {
          // @ts-ignore
          node.addEventListener(event, events[event])
        }
      })
      // Save a reference to the node
      ref.current = node
    },
    [events]
  )

  return [setRef]
}

export function IProov({ userId, mode, assurance_type, onDone, sdkOptions, inviteCode }: IProovProps) {
  const { t } = useTranslation()

  const canNext: EventCallback = () => {
    console.log("onPassed")
    onDone()
  }

  const cantNext: EventCallback = (event) => {
    console.log("canceled")
  }

  const ready: EventCallback = (event) => {
    const buttons = event.srcElement.querySelectorAll<HTMLButtonElement>(".action-restart")
    if (buttons) {
      buttons.forEach((button) => button.addEventListener("click", retryTransaction))
    }
  }

  const retryTransaction = () => {
    console.log("restart")
    setTokenData(null)
    getToken(inviteCode)
  }

  const progress: EventCallback = (event: SDKEventData) => {
    const { progress, message } = event.detail
    const progressInner = event.srcElement.querySelector<HTMLElement>(".progress__inner")
    const progressText = event.srcElement.querySelector<HTMLElement>(".progress__text")
    if (progressInner) {
      progressInner.style.width = progress + "%"
    }

    if (progressText) {
      progressText.innerText = message
    }
  }

  const [tokenData, setTokenData] = useState<any>(null)
  const [iProovElement] = useHookWithRefCallback({
    passed: canNext,
    canceled: cantNext,
    error: cantNext,
    failed: cantNext,
    permission_denied: cantNext,
    permission: cantNext,
    unsupported: cantNext,
    progress: progress,
    ready: ready,
  })

  const getToken = (inviteCode: MaybeUndefined<string>) => {
    const payload: TokenPayload = {
      userId,
      mode,
      assurance_type,
      inviteCode: inviteCode,
    }

    fetchToken(payload)
      .catch(console.error)
      .then((response) => {
        setTokenData(response)
      })
  }

  useEffect(() => {
    getToken(inviteCode)
  }, [userId, mode, assurance_type, inviteCode])

  if (!tokenData) {
    return <Loader />
  }

  return (
    <section className="panel">
      <iproov-me
        ref={iProovElement}
        token={tokenData.token}
        base_url={tokenData.base_url}
        custom_title={sdkOptions.title}
        {...sdkOptions}
      >
        <div slot="ready">
          <h3>{t("Ready to iProov")}</h3>
        </div>
        <div slot="grant_permission">
          <h3 className="iproov-lang-heading">{t("Camera permission required")}</h3>
          <p>{t("We only need your webcam for a few seconds.")}</p>
        </div>
        <div slot="grant_button">
          <p>
            <button>{t("Grant Camera Permission")}</button>
          </p>
          <p>
            {t("For full details, see our")} <a href="https://www.iproov.com/privacy-policy">{t("Privacy Policy")}</a>
          </p>
        </div>
        <div slot="button">
          <p>{t("Click below to start the iProov process:")}</p>
          <button className="ipbutton--lozenge">{t("Scan face")}</button>
        </div>
        <div slot="error" className="state state--error">
          <h3>{t("Error")}</h3>
          <p>
            {t("Something went wrong with iProov. If this keeps happening,")}
            <a href="mailto:support@iproov.com">{t("let us know")}</a>
            {t("so we can take a look and make things right.")}
          </p>
          <pre className="detail iproov-lang-term"></pre>
          <button className="ipbutton--lozenge action-restart">{t("Try again")}</button>
          <a className="ipbutton ipbutton--texas-rose ipbutton--lozenge" href="mailto:support@iproov.com">
            {t("Contact support")}
          </a>
        </div>
        <div slot="failed" className="state state--failed">
          <h3>{t("Failed")}</h3>
          <p>{t("Either you're a cyber criminal, or we couldn't quite match you. Care to try again?")}</p>
          <pre className="detail iproov-lang-term"></pre>
          <button className="ipbutton--lozenge action-restart">{t("Try again")}</button>
        </div>
        <div slot="canceled" className="state state--canceled">
          <h3>{t("Canceled")}</h3>
          <p>{t("User exited out of iProov")}</p>
          <button className="ipbutton--lozenge action-restart">{t("Restart iProov")}</button>
        </div>
        <div slot="passed" className="state state--passed">
          <h3>{t("Passed")}</h3>
          <p>{t("You have iProoved successfully.")}</p>
        </div>
        <div slot="progress" className="progress" aria-roledescription="progress">
          <div className="progress__inner"></div>
          <span className="progress__text">{t("Progressing")}</span>
        </div>
      </iproov-me>
    </section>
  )
}
