import React, { ChangeEvent, useRef, useState } from "react"
import { InviteCode } from "../InviteCode"
import { RandomName } from "../RandomName"
import { IProov } from "../IProov"
import { Step } from "../FlowConfigurator"
import Wizard from "../Wizard"
import { Terms } from "../Terms"

const availableFlowSteps = {
  InviteCode: InviteCode,
  RandomName: RandomName,
  IProov: IProov,
  Terms: Terms,
}

export function Flow() {
  const [config, setConfig] = useState<any>("")
  const [configValue, setConfigValue] = useState<any>("")
  const [go, setGo] = useState<boolean>(false)
  const [state, setState] = useState({
    config: undefined,
  })
  type StepsHandle = React.ElementRef<typeof Wizard>
  const ref = useRef<StepsHandle>(null)

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setConfigValue(e.target.value)
    const json = JSON.parse(e.target.value)
    setConfig(json)
  }

  const onDone = (goNext: boolean = false, stateUpdate: any = {}) => {
    if (ref.current) {
      ref.current.onStepDone(goNext)
    }

    setState({
      ...state,
      ...stateUpdate,
    })
  }

  const renderSteps = () => {
    return (
      <>
        <Wizard ref={ref}>
          {config.steps.map((step: Step, key: number) => {
            const Component = availableFlowSteps[step.component]
            return (
              // @ts-ignore
              <Component
                {...step.config}
                {...state}
                mode="enrol"
                key={key}
                assurance_type={"genuine_presence"}
                onDone={onDone}
              />
            )
          })}
        </Wizard>
      </>
    )
  }

  const renderFlowInit = () => {
    return (
      <div>
        <h2>Paste flow config below</h2>
        <textarea value={configValue} onChange={onChange} />
        <p>
          <button onClick={() => setGo(true)}>Start</button>
        </p>
      </div>
    )
  }

  return (
    <div className="App">
      <a href="/" aria-label="Face Scan">
        <h1>
          <img className="logo" src="img/iproov_logo.svg" width="100" alt="iProov" />
        </h1>
      </a>
      {go ? renderSteps() : renderFlowInit()}
    </div>
  )
}
