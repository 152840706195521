// if `REACT_APP_TOKEN_API` is not set try "" to use current origin
const { REACT_APP_TOKEN_API = "" } = process.env
export type AssuranceType = "liveness" | "genuine_presence"

export type TokenPayload = {
  mode: "enrol" | "verify"
  userId: string
  assurance_type: AssuranceType
  inviteCode?: string
}

export const fetchToken = async (payload: TokenPayload) => {
  const res = await fetch(`${REACT_APP_TOKEN_API}/api/claim/${payload.mode}/token`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      mode: payload.mode,
      user_id: payload.userId,
      assurance_type: payload.assurance_type,
      invite_code: payload.inviteCode,
    }),
  })

  return await res.json()
}
