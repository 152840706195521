import { useState, FormEvent, MouseEvent } from "react"

type TermsProps = {
  onDone: (goNext: boolean) => void
}

export function Terms({ onDone }: TermsProps) {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setAcceptedTerms(event.target.checked)
  }

  const submit = (e: FormEvent | MouseEvent) => {
    e.preventDefault()
    if (!acceptedTerms) {
      setError(true)
    } else {
      onDone(true)
    }
  }

  return (
    <section className="panel content">
      <div className="header">
        <h2>Terms &amp; Conditions</h2>
      </div>
      <form onSubmit={submit}>
        <p>
          By accepting you agree to our
          <a href="https://www.iproov.com/iproov-services-acceptable-use-policy" target="_blank">
            Privacy Policy
          </a>
          and
          <a href="https://www.iproov.com/iproov-privacy-policy-for-user-demonstration-app-survey" target="_blank">
            Acceptable Use Policy
          </a>
          . You also give consent to record, upload and use your image, and to collect associated survey data.
        </p>
        <p>To accept these terms and give consent, tick the box below.</p>
        <div className="row">
          <label>
            <input type="checkbox" id="terms_agreed" name="terms_agreed" onChange={onChange} checked={acceptedTerms} />
            &nbsp;<span className="label-terms">I accept these terms and give consent.</span>
          </label>
        </div>
        {error && <div className="error-container">Please accept Terms &amp; Conditions</div>}
        <div className="row">
          <button type="button" onClick={submit}>
            Continue
          </button>
        </div>
      </form>
    </section>
  )
}
