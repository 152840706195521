import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { FlowConfigurator } from "./components/FlowConfigurator"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Flow } from "./components/Flow"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/flow-configurator",
    element: <FlowConfigurator />,
  },
  {
    path: "/flow",
    element: <Flow />,
  },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
