import { AssuranceType, TokenPayload } from "../../services/tokenProvider"

export const CANVAS_FILTER = {
  Classic: "classic",
  Clear: "clear",
  Shaded: "shaded",
  Vibrant: "vibrant",
  Blur: "blur",
} as const

export type CanvasFilter = (typeof CANVAS_FILTER)[keyof typeof CANVAS_FILTER]

export type SDKOptions = {
  title: string
  filter: CanvasFilter
  logo_image: string | null
  surround_color: string
  title_text_color: string
  oval_stroke_color: string
  prompt_text_color: string
  close_button_image: string | null
  enable_screenshots: boolean
  close_button_tint_color: string
  prompt_background_color: string
  ready_oval_stroke_color: string
  completed_oval_stroke_color: string
  not_ready_oval_stroke_color: string
}

export type Mode = "enrol" | "verify"
export type TransactionConfiguration = TokenPayload & {
  sdkOptions: SDKOptions
}

export class TransactionConfig {
  private sdkOptions: SDKOptions = {
    title: "",
    filter: "classic",
    logo_image: "",
    surround_color: "",
    title_text_color: "",
    oval_stroke_color: "",
    prompt_text_color: "",
    close_button_image: "",
    enable_screenshots: false,
    close_button_tint_color: "",
    prompt_background_color: "",
    ready_oval_stroke_color: "",
    completed_oval_stroke_color: "",
    not_ready_oval_stroke_color: "",
  }
  private baseUrl: string = ""
  private mode: Mode = "enrol"
  private userId: string = ""
  private assurance_type: AssuranceType = "genuine_presence"
  private inviteCode: string | undefined = undefined

  public setSDKOptions(sdkOptions: SDKOptions) {
    this.sdkOptions = sdkOptions
  }

  public setMode(mode: string) {
    this.mode = mode as Mode
  }

  public getMode() {
    return this.mode
  }

  public setAssuranceType(assuranceType: string) {
    this.assurance_type = assuranceType as AssuranceType
  }

  public getAssuranceType() {
    return this.assurance_type
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public getBaseUrl() {
    return this.baseUrl
  }

  public updateSDKOption(name: keyof SDKOptions, value: any) {
    if (name in this.sdkOptions) {
      // @ts-ignore
      this.sdkOptions[name] = value
    }
  }

  public getSDKOptions(): SDKOptions {
    return this.sdkOptions
  }

  public getTransactionConfiguration(): TransactionConfiguration {
    return {
      sdkOptions: this.sdkOptions,
      assurance_type: this.assurance_type,
      userId: this.userId,
      mode: this.mode,
      inviteCode: this.inviteCode,
    }
  }
}
