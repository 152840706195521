import "./i18n/config"
import React, { useState } from "react"

import "./App.css"

import { WebDemoApp } from "./components/WebDemoApp"
import { InviteCode } from "./components/InviteCode"
import { ScopeSetterOnDone } from "./components/Wizard"

export type StringSetterFn = (a: string) => void

function App() {
  const [inviteCode, setInviteCode] = useState<string>("")
  const [isPublicDemo, setIsPublicDemo] = useState<boolean>(false)

  const applyInviteCode: ScopeSetterOnDone = (goNext?: boolean, stateUpdate: any = {}): void => {
    if (stateUpdate.inviteCode) {
      setInviteCode(stateUpdate.inviteCode)
      setIsPublicDemo(true)
    }
  }

  return (
    <div className="App">
      {!inviteCode && <InviteCode onDone={applyInviteCode} />}
      {isPublicDemo && <WebDemoApp inviteCode={inviteCode} />}
    </div>
  )
}

export default App
