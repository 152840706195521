import { RandomName } from "../RandomName"
import React, { useEffect, useRef, useState } from "react"
import { IProov } from "../IProov"
import { AssuranceType } from "../../services/tokenProvider"
import { fetchWebDemoInvite } from "../../services/webDemoInviteProvider"
import Wizard from "../Wizard"
import { Terms } from "../Terms"
import { useTranslation } from "react-i18next"
import { Maybe } from "../../global"
import { Loader } from "../Loader"
import { SDKOptions } from "../IProov/TransactionConfig"

export type WebDemoAppProps = {
  inviteCode: string
}
export type APIDetails = {
  secret: string
  api_key: string
  base_url: string
}

export type InviteColors = {
  text: string
  primary: string
  secondary: string
  background: string
  button_title: string
}

export type InviteData = {
  api: APIDetails
  logo: string
  name: string
  colors: InviteColors
  intro_text: string
  sdk_options: SDKOptions
  schema_version: number
  document_scanner: boolean | null
  enrol_assurance_type: AssuranceType
  verify_assurance_type: AssuranceType
  document_scan_assurance_type: AssuranceType
}

export type StateData = {
  inviteCode?: string
  userId?: string
}

export function WebDemoApp({ inviteCode }: WebDemoAppProps) {
  const [inviteData, setInviteData] = useState<Maybe<InviteData>>(null)
  const [fetchingError, setFetchingError] = useState<Maybe<string>>(null)
  const [state, setState] = useState<StateData>({})
  const { t } = useTranslation()

  type StepsHandle = React.ElementRef<typeof Wizard>
  const ref = useRef<StepsHandle>(null)

  useEffect(() => {
    fetchWebDemoInvite(inviteCode)
      .catch((error) => {
        console.error("error fetching invite", error)
        setFetchingError(t("Error during fetching invite"))
      })
      .then((response) => {
        if (response.status_code === 404) {
          setFetchingError(t("Invite code not found"))
          return
        }
        setInviteData(response)
      })
  }, [state.inviteCode])

  const onDone = (goNext: boolean = false, stateUpdate: any = {}) => {
    if (ref.current) {
      ref.current.onStepDone(goNext)
    }

    setState({
      ...state,
      ...stateUpdate,
    })
  }

  if (fetchingError) {
    return (
      <section className="intro panel">
        <div className="header">
          <h2>{fetchingError}</h2>
        </div>
        <div className="row">{t("Check if your invite code is valid and retry.")}</div>
        <div className="row">
          <button type="button" onClick={() => window.location.reload()}>
            {t("Retry")}
          </button>
        </div>
      </section>
    )
  }

  if (!inviteData) {
    return <Loader />
  }

  return (
    <div>
      {inviteData && (
        <div className="topBar">
          <h1>
            <a href="/" aria-label="Face Scan">
              <img className="logo" src={inviteData.logo} alt="Logo" />
            </a>
          </h1>
        </div>
      )}
      <Wizard ref={ref}>
        <div key={6}>
          <Terms onDone={onDone} />
        </div>
        <div key={0}>
          <RandomName onDone={onDone} />
        </div>
        <div key={1}>
          {state.userId && inviteData && (
            <IProov
              assurance_type={inviteData.enrol_assurance_type}
              mode="enrol"
              userId={state.userId}
              onDone={onDone}
              sdkOptions={inviteData.sdk_options}
              inviteCode={inviteCode}
            />
          )}
        </div>
        <div key={2}>
          {state.userId && inviteData && (
            <IProov
              assurance_type={inviteData.verify_assurance_type}
              mode="verify"
              userId={state.userId}
              onDone={onDone}
              sdkOptions={inviteData.sdk_options}
              inviteCode={inviteCode}
            />
          )}
        </div>
        <div key={3}>
          <div>{t("Thank you for participating")}</div>
        </div>
      </Wizard>
    </div>
  )
}
