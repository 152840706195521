import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ScopeSetterOnDone } from "../Wizard"

type InviteCodeProps = {
  onDone: ScopeSetterOnDone
}

export function InviteCode({ onDone }: InviteCodeProps) {
  const [inviteCode, setInviteCode] = useState<string>("")
  const { t } = useTranslation()

  return (
    <section className="intro panel">
      <div className="header">
        <h2>Welcome</h2>
      </div>
      <div className="row">
        <form onSubmit={() => onDone(true, { inviteCode })}>
          <div className="row">
            <label htmlFor="code">{t("Please enter your invite code:")}</label>
            <input
              value={inviteCode}
              id="code"
              type="text"
              placeholder={t<string>("Invite Code")}
              onChange={(e) => {
                setInviteCode(e.target.value)
              }}
              required
            />
          </div>
          <div className="row">
            <button onClick={() => onDone(true, { inviteCode })}>{t("Start")}</button>
          </div>
        </form>
      </div>
    </section>
  )
}
