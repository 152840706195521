import React, { useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"

export type StepsProps = {
  children: JSX.Element[]
}

export type StepsHandle = {
  onStepDone: (goNext?: boolean) => void
}

export type ScopeSetterOnDone = (goNext?: boolean, scopeChange?: any) => void

const Wizard: React.ForwardRefRenderFunction<StepsHandle, StepsProps> = ({ children }, forwardedRef) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [canGoNext, setCanGoNext] = useState<boolean>(false)
  const { t } = useTranslation()

  // https://react.dev/reference/react/useImperativeHandle
  // this hooks allows onStepDone to be called via ref
  useImperativeHandle(forwardedRef, () => ({
    onStepDone(goNext: boolean = false) {
      if (currentStep + 1 <= children.length) {
        setCanGoNext(true)
        if (goNext) {
          onNextClick()
        }
      }
    },
  }))

  const onNextClick = () => {
    setCurrentStep(currentStep + 1)
    setCanGoNext(false)
  }

  return (
    <div>
      {children[currentStep - 1]}
      {canGoNext && <button onClick={onNextClick}>{t("Next")}</button>}
    </div>
  )
}

export default React.forwardRef(Wizard)
