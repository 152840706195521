const { REACT_APP_DEMO_API } = process.env

export const fetchWebDemoInvite = async (inviteCode: string) => {
  const res = await fetch(`${REACT_APP_DEMO_API}/api/demos/invite/${inviteCode}`, {
    method: "GET",
    headers: { "content-type": "application/json" },
  })

  return await res.json()
}
