import { useEffect, useState } from "react"
import { dictionary } from "./dictionary"
import "./index.css"
import { useTranslation } from "react-i18next"
import { ScopeSetterOnDone } from "../Wizard"

export type RandomNameProps = {
  onDone: ScopeSetterOnDone
}

export function RandomName({ onDone }: RandomNameProps) {
  const [userId, setUserId] = useState<string>("")
  const { t } = useTranslation()

  const pickRandom = (arr: string[], count: number): string[] => {
    let _arr = [...arr]
    return [...Array(count)].map(() => _arr.splice(Math.floor(Math.random() * _arr.length), 1)[0])
  }

  const generateRandomName = (): void => {
    setUserId(pickRandom(dictionary, 3).join("-"))
  }

  useEffect(() => {
    generateRandomName()
    // since generateRandomName is also used outside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="panel">
      <form>
        <label htmlFor="code">{t("Please enter user name:")}</label>
        <div className="row RandomNameWrapper">
          <input
            value={userId}
            type="text"
            onChange={(e) => {
              setUserId(e.target.value)
            }}
            className="RandomNameInput"
          />
          <button onClick={() => generateRandomName()} className="RandomNameButton">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#ffffff"
              height="20px"
              width="20px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 214.367 214.367"
            >
              <path d="M202.403,95.22c0,46.312-33.237,85.002-77.109,93.484v25.663l-69.76-40l69.76-40v23.494  c27.176-7.87,47.109-32.964,47.109-62.642c0-35.962-29.258-65.22-65.22-65.22s-65.22,29.258-65.22,65.22  c0,9.686,2.068,19.001,6.148,27.688l-27.154,12.754c-5.968-12.707-8.994-26.313-8.994-40.441C11.964,42.716,54.68,0,107.184,0  S202.403,42.716,202.403,95.22z" />
            </svg>
          </button>
        </div>
        <div className="row">
          <button type="button" onClick={() => onDone(true, { userId })}>
            {t("Apply")}
          </button>
        </div>
      </form>
    </section>
  )
}
