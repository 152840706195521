import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import translation from "./en/translation.json"

const { REACT_APP_DEV = false } = process.env

export const resources = {
  en: {
    translation,
  },
}

i18next.use(initReactI18next).init({
  lng: "en", // if you're using a language detector, do not define the lng option
  debug: REACT_APP_DEV as boolean,
  resources,
})
