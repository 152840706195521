import "./index.css"

export function Loader() {
  return (
    <div className="loading-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
