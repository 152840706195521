import { ChangeEvent, useState } from "react"
import { SDKOptions, TransactionConfig } from "../IProov/TransactionConfig"

const availableComponents = ["RandomName", "InviteCode", "IProov", "Terms"] as const
export type StepComponentName = (typeof availableComponents)[number]
export type Step = {
  component: StepComponentName
  config?: TransactionConfig
  sdkOptions?: SDKOptions
}

type SDKOptionChangeEvent = ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>

export const FlowConfigurator = () => {
  const [steps, setSteps] = useState<Step[]>([])
  const [currentStep, setCurrentStep] = useState<Step>({
    component: "IProov",
  })

  const addStep = () => {
    //#todo add Validation errors to addStep
    if (!currentStep.component) {
      return
    }

    if (currentStep.component === "IProov") {
      // #todo think how to simplify
      setSteps([
        ...steps,
        {
          ...currentStep,
          config: new TransactionConfig(),
        },
      ])

      // setCurrentStep({
      //     component: null
      // })
      return
    }

    setSteps([...steps, currentStep])

    // setCurrentStep({
    //     component: null
    // })
  }

  const changeComponent = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value !== "") {
      setCurrentStep({
        ...currentStep,
        component: event.target.value as StepComponentName,
      })
    }
  }

  const renderAddStep = () => {
    return (
      <div>
        <label htmlFor="component-select">Add new step component</label>
        <select onChange={changeComponent} id="component-select">
          {availableComponents.map((component) => {
            return <option value={component}>{component}</option>
          })}
        </select>
      </div>
    )
  }

  type SelectOptions = {
    label: string
    value: string
  }

  type OptionDetails = {
    type: string
    options?: SelectOptions[]
  }

  const sdkOptions: { [key: string]: OptionDetails } = {
    title: {
      type: "string",
    },
    filter: {
      type: "select",
      options: [
        { label: "Classic", value: "classic" },
        { label: "Shaded", value: "shaded" },
        { label: "Vibrant", value: "vibrant" },
        { label: "Clear", value: "clear" },
        { label: "Blur", value: "blur" },
      ],
    },
    logo_image: {
      type: "string",
    },
    surround_color: {
      type: "string",
    },
    title_text_color: {
      type: "string",
    },
    oval_stroke_color: {
      type: "string",
    },
    prompt_text_color: {
      type: "string",
    },
    close_button_image: {
      type: "string",
    },
    enable_screenshots: {
      type: "boolean",
    },
    close_button_tint_color: {
      type: "string",
    },
    prompt_background_color: {
      type: "string",
    },
    ready_oval_stroke_color: {
      type: "string",
    },
    completed_oval_stroke_color: {
      type: "string",
    },
    not_ready_oval_stroke_color: {
      type: "string",
    },
  }

  const updateSDKOption = (value: string | boolean, option: string, key: number) => {
    const config = steps[key].config
    if (config) {
      config.updateSDKOption(option as keyof SDKOptions, value)
    }
    setSteps([...steps])
  }

  const updateOption = (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>,
    key: number,
    setter: string
  ) => {
    const value = e.target.value
    const config = steps[key].config as TransactionConfig
    if (config) {
      // @ts-ignore
      config[setter](value)
    }
    setSteps([...steps])
  }

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, option: string, key: number) => {
    updateSDKOption(event.target.checked, option, key)
  }
  const onChange = (event: SDKOptionChangeEvent, option: string, key: number) => {
    updateSDKOption(event.target.value, option, key)
  }

  const renderOptionControl = (option: string, step: Step, key: number) => {
    const options = step.config?.getSDKOptions()
    const id = `${key}_${option}_input`
    if (!options) return
    //#TODO think of better handling of types here
    const value: string = options[option as keyof SDKOptions] as string
    switch (sdkOptions[option].type) {
      case "string":
        return <input type="text" id={id} value={value} onChange={(e) => onChange(e, option, key)} />
      case "boolean":
        return <input type="checkbox" id={id} value="true" onChange={(e) => onCheckboxChange(e, option, key)} />
      case "select":
        const options = sdkOptions[option].options
        if (options) {
          return (
            <select
              id={id}
              className="select"
              value={step.config?.getSDKOptions()[option as keyof SDKOptions] as string}
              onChange={(e) => onChange(e, option, key)}
            >
              {options.map((option) => {
                return <option value={option.value}>{option.label}</option>
              })}
            </select>
          )
        }

        return ""

      default:
        return <div>Missing implementation for {sdkOptions[option].type}</div>
    }
  }

  const renderIprovConfig = (step: Step, key: number) => {
    const baseUrl = step.config?.getBaseUrl() || ""
    const assuranceType = step.config?.getAssuranceType()
    const mode = step.config?.getMode()
    return (
      <div key={key}>
        Component: {step.component}
        Options:
        <table>
          <tr>
            <th>Option</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>
              <label htmlFor={`${key}_base_url`}>Base url</label>
            </td>
            <td>
              <input
                type="text"
                value={baseUrl}
                id={`${key}_base_url`}
                onChange={(e) => updateOption(e, key, "setBaseUrl")}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor={`${key}_assurance_type`}>Assurance type</label>
            </td>
            <td>
              <select
                className="select"
                value={assuranceType}
                id={`${key}_assurance_type`}
                onChange={(e) => updateOption(e, key, "setAssuranceType")}
              >
                <option value="liveness">Liveness</option>
                <option value="genuine_presence">Genuine Presence</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor={`${key}_mode`}>Mode</label>
            </td>
            <td>
              <select className="select" value={mode} onChange={(e) => updateOption(e, key, "setMode")}>
                <option value="enrol">Enrol</option>
                <option value="verify">Verify</option>
              </select>
            </td>
          </tr>
          {Object.keys(sdkOptions).map((option: string) => {
            const id = `${key}_${option}_input`
            return (
              <tr>
                <td>
                  <label htmlFor={id}>{option}</label>
                </td>
                <td>{renderOptionControl(option, step, key)}</td>
              </tr>
            )
          })}
        </table>
      </div>
    )
  }

  const renderStep = (step: Step, key: number) => {
    if (step.component === "IProov") {
      return renderIprovConfig(step, key)
    }
    return <div key={key}>Component: {step.component}</div>
  }

  const getOutput = () => {
    return {
      steps,
    }
  }

  return (
    <>
      <div>{steps.map((step, key) => renderStep(step, key))}</div>
      <div>{renderAddStep()}</div>
      <button onClick={addStep}>Add step</button>
      <pre>{JSON.stringify(getOutput(), undefined, 2)}</pre>
    </>
  )
}
